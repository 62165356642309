<template lang="pug">
#app
  v-container(grid-list-xl, fluid)
    v-layout(v-if="$root.user.permissao != 0", row, wrap)
      AccessLock
    v-layout(v-if="$root.user.permissao == 0", row, wrap)
      v-flex(lg12)
        v-card
          v-toolbar(card, color='white')
            v-text-field.hidden-sm-and-down(flat, solo, prepend-icon='search', placeholder='Buscar usuário', v-model='search', hide-details)
            v-switch.mt-4(:false-value="0", :true-value="1", :color="filter.ativo ? 'success': ''", :label="filter.ativo ? 'Usuário ativos' : 'Usuário inativos'", v-model="filter.ativo")
            v-btn(color="bt-primary", @click.native="openDialogUsuario(null)").white--text Cadastrar
          v-divider
          v-card-text.pa-1
            v-data-table(:headers='headers', :search='search', :items='list', :rows-per-page-items="[25,50,75,{text:'Todos','value':-1}]", :loading="loading").elevation-0
              template(slot='items', slot-scope='props')
                td(width="5%").text-xs-center {{ props.item.id }}
                td(width="10%").text-xs-center {{ props.item.codigo }}
                td.text-xs-left {{ props.item.nome }}
                td.text-xs-left {{ props.item.email }}
                td.text-xs-left
                    v-chip(label, small, :color="['#318837', '#FF9800'][props.item.permissao]").text-uppercase.white--text.font-weight-bold {{ props.item.permissao | permissaoUsuario }}
                td(width="15%").text-xs-center
                  v-tooltip(top)
                    v-btn(slot="activator", flat, icon, fab, dark, color='bt-primary', small, @click.native="openDialogUsuario(props.item)")
                      v-icon edit
                    span Editar
                  v-tooltip(top)
                    v-btn(slot="activator", flat, icon, fab, dark, :color="props.item.ativo ? 'red' : 'warning'", small, @click="changeAtivo(props.item)")
                      v-icon {{ props.item.ativo ? 'delete' : 'restore' }}
                    span {{ props.item.ativo ? 'Desativar' : 'Ativar' }}
                    
    v-dialog(v-model="dialogUsuario", width="auto", scrollable, max-width="800px", persistent)
      v-card
        form(@submit.prevent="salvar('usuario')", data-vv-name="usuario")
          v-card-title
            span.title {{ usuarioEdit && usuarioEdit.id ? 'Editar usuário' : 'Cadastrar usuário' }}
            v-spacer
            v-tooltip(top)
              v-btn(icon, slot="activator", @click.native="$validator.pause(); dialogUsuario = null; usuarioEdit = {}").pa-0.mx-0.my-0.ma-0
                v-icon close
              span Fechar
          v-divider
          v-card-text
            v-layout(row, wrap).pa-1
              v-flex.xs6.md6.pa-1
                v-text-field(disabled, color="gray", label='Código', :error-messages="errors.collect('codigo')", data-vv-name="codigo", type="text", v-model='usuarioEdit.codigo', placeholder="XXXXXXX")
              v-flex.xs6.md6.pa-1
                v-text-field(color="gray", label='Nome', required, v-validate="'required'", :error-messages="errors.collect('nome')", data-vv-name="nome", type="text", v-model='usuarioEdit.nome', autofocus)
            v-layout(row, wrap).pa-1
              v-flex.xs6.md6.pa-1
                v-text-field(color="gray", label='Email', required, v-validate="'required|email'", :error-messages="errors.collect('email')", data-vv-name="email", type="text", v-model='usuarioEdit.email')
              v-flex.xs2.md2.pa-1
              v-flex.xs4.md4.pa-1
                v-radio-group(v-model="usuarioEdit.permissao", row)
                  v-radio(color="primary", label='Gerente', :value="0")
                  v-radio(color="primary", label='Auditor', :value="1")
          v-divider
          v-card-actions.headline.grey.lighten-2
            v-layout(row, wrap).pa-1
              v-flex.xs12.text-xs-right
                v-btn(color="black", flat, @click.native="dialogUsuario = false; usuarioEdit = {}") Fechar
                v-btn(type="submit", color="bt-primary", :loading='loading').white--text Salvar

</template>

<script>
import _ from 'lodash';
import AccessLock from '@/components/AccessLock';

export default {
  components: {
    AccessLock
  },
  data: () => ({
    filter: { ativo: 1 },
    usuarios: [],
    usuario: {},
    usuarioEdit: {},
    dialogUsuario: false,
    headers: [
      { text: '#', align: 'left', value: 'id' },
      { text: 'Código', align: 'left', value: 'codigo' },
      { text: 'Nome', align: 'left', value: 'nome' },
      { text: 'Email', align: 'left', value: 'email' },
      { text: 'Permissão', align: 'left', value: 'permissao' },
      { text: 'Ações', align: 'center', sortable: false },
    ],
    search: '',
    loading: false
  }),
  computed: {
    list () {
      return _.filter(this.usuarios, { ativo: this.filter.ativo });
    }
  },
  mounted () {
    this.getUsuarios();
  },
  methods: {
    async getUsuarios () {
      const vm = this;
      try {
        vm.loading = true;
        const response = await vm.$axios.get('/usuario', { params: { fields: "usuario_licencas" } });
        vm.usuarios = _.reject(response.data.rows, { id : vm.$root.user.id});
        vm.loading = false;
      } catch (error) {
        let err = error.response && error.response.data ? error.response.data.error : 'Ocorreu um erro ao buscar os dados. Atualize a página!';
        window.getApp.$emit('APP_ALERT', { color: 'red', text: err });
        vm.loading = false;
      }
    },
    async changeAtivo (usuario) {
      const vm = this;
      try {
        const obj = _.cloneDeep(usuario);
        obj.ativo = obj.ativo ? 0 : 1;
        const response = await vm.$axios.put(`/usuario/${obj.id}`, obj);
        usuario.ativo = response.data.ativo;
        window.getApp.$emit('APP_ALERT', { color: obj.ativo ? 'success' : 'orange', text: `Usuário ${usuario.ativo ? 'ativado' : 'desativado'} com sucesso!` });
      } catch (error) {
        let err = error.response && error.response.data ? error.response.data.error : 'Ocorreu um erro ao salvar o usuário. Tente novamente!';
        window.getApp.$emit('APP_ALERT', { color: 'red', text: err });
      }
    },
    async remove (usuario) {
      const vm = this;
      try {
        const res = await vm.$confirm('Deseja realmente excluir?<br> Essa ação não poderá ser desfeita!');
        if (!res) return;
        const response = await vm.$axios.delete(`/usuario/${usuario.id}`);
        vm.usuarios = _.reject(vm.usuarios, { id: usuario.id });
        window.getApp.$emit('APP_ALERT', { color: 'success', text: 'Usuário excluído com sucesso!' });
      } catch (error) {
        let err = error.response && error.response.data ? error.response.data.error : 'Ocorreu um erro ao excluir o usuário. Tente novamente!';
        window.getApp.$emit('APP_ALERT', { color: 'red', text: err });
      }
    },
    async openDialogUsuario (usuario) {
      const vm = this;
      if (usuario) {
          vm.usuarioEdit = _.cloneDeep(usuario);
          if (vm.usuarioEdit && vm.usuarioEdit.id) {
          const response = await vm.$axios.get(`/usuario/${vm.usuarioEdit.id}`);
          vm.usuarioEdit = response.data;
          }
      }
      vm.dialogUsuario = true;
    },
    async save (usuario) {
      const vm = this;
      try {
        vm.loading = true;
        const response = await vm.$axios.post('/usuario', usuario);
        if (response && response.data && response.data.id) {
          vm.usuarios.push(response.data);
          vm.usuario = false;
        }
        vm.dialogUsuario = false;
        vm.usuarioEdit = {};
        vm.loading = false;
        window.getApp.$emit('APP_ALERT', { color: 'success', text: 'Usuário salvo com sucesso!' });
      } catch (error) {
        vm.loading = false;
        let err = error.response && error.response.data ? error.response.data.error : 'Ocorreu um erro ao salvar o usuário. Tente novamente!';
        window.getApp.$emit('APP_ALERT', { color: 'red', text: err });
      }
    },
    async update (usuario) {
      const vm = this;
      try {
        vm.loading = true;
        const response = await vm.$axios.put(`/usuario/${usuario.id}`, usuario);
        const index = _.findIndex(vm.usuarios, { id: usuario.id });
        const usuarios = _.reject(vm.usuarios, { id: usuario.id });
        vm.usuarios = [...usuarios.slice(0, index), response.data, ...usuarios.slice(index)];
        vm.dialogUsuario = false;
        vm.usuarioEdit = {};
        vm.loading = false;
        window.getApp.$emit('APP_ALERT', { color: 'success', text: 'Usuário salvo com sucesso!' });
      } catch (error) {
        let err = error.response && error.response.data ? error.response.data.error : 'Ocorreu um erro ao atualizar o usuário. Tente novamente!';
        window.getApp.$emit('APP_ALERT', { color: 'red', text: err });
        vm.loading = false;
      }
    },
    async salvar (scope) {
      const vm = this;
      try {
        vm.loading = true;  
        const result = await vm.$validator.validateAll();
        if (!result) throw 'Preencha todos os campos corretamente!';
        if (scope === 'usuario') {
          vm[vm.usuarioEdit.id ? 'update' : 'save'](vm.usuarioEdit);
        }
        vm.$validator.reset();
      } catch (error) {
        window.getApp.$emit('APP_ALERT', { color: 'red', text: error });
        vm.loading = false;
      }
    },
  },
};
</script>

<style scoped>

</style>

